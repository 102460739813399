<template>
  <div class="center">
    <div class="left">
      <div class="ranking bg-color-black">
        <span style="color: #fff;font-size: 15px;">活动场次排行榜</span>
        <dv-scroll-ranking-board :config="ranking" style="height:180px" />
      </div>
    </div>
    <div class="main">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="ml-3 colorBlue fw-b">{{item.title}}</p>
        <div style="width: auto;">
          <dv-digital-flop :config="item.number" style="width:100px;height:50px;" />
        </div>
      </div>
    </div>
<!--    <div class="right">-->
<!--      <div class="water">-->
<!--        <dv-water-level-pond :config="water" style="height: 120px" />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
// import taskStatusOne from "./taskLeft1";
// import taskStatusTwo from "./taskLeft2";

export default {
  data() {
    return {
      sportData:[
        {
          name: "长沙市",
          value: 24
        },
        {
          name: "株洲市",
          value: 0
        },
        {
          name: "湘潭市",
          value: 0
        },
        {
          name: "衡阳市",
          value: 0
        },
        {
          name: "常德市",
          value: 0
        },
        {
          name: "益阳市",
          value:0
        },
        {
          name: "娄底市",
          value: 0
        },
        {
          name: "郴州市",
          value: 0
        },
        {
          name: "永州市",
          value: 0
        },
        {
          name: "怀化市",
          value: 0
        },
        {
          name: "湘西",
          value: 0
        },
        {
          name: "张家界",
          value: 0
        },
        {
          name: "岳阳市",
          value: 0
        },
        {
          name: "邵阳市",
          value: 0
        },
      ],  // 活动场次
      sumWork:[
        {
          sumWorkList:[],
        },
        {
          sumWorkList:[]
        },
        {
          sumWorkList:[]
        },
        {
          sumWorkList:[]
        },
        {
          sumWorkList:[]
        },
        {
          sumWorkList:[]
        },
      ],  //累计各项数据
      titleItem: [
        {
          title: "累计活动场次",
          number: {
            number: this.sumWorkList1,
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "累计服务人次",
          number: {
            number: this.sumWorkList2,
            toFixed: 1,
            content: "{nt}"
          },
        },
        {
          title: "累计社会组织参与数",
          number: {
            number: [],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "累计投入款物（万元）",
          number: {
            number: [],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "累计志愿者人数",
          number: {
            number: [],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "累计覆盖学校数量",
          number: {
            number: [],
            toFixed: 1,
            content: "{nt}"
          }
        },
      ],
      ranking: {
        data: [],
        waitTime: 4000,
        // carousel: "page", //整页滚动
        unit: "次"
      },
      water: {
        data: [45],
        shape: 'roundRect',
        formatter:'{value}%',
        waveNum:3
      },

    };
  },
  methods: {
    setChart(){
      this.ranking = { data: this.sportData,waitTime: 4000, unit: "次"};
      for (var i=0;i<this.titleItem.length;i++){
        this.titleItem[i].number = { number:[Number(this.sumWork[i].sumWorkList)], toFixed: i==1?0:i==3?3:1, content: "{nt}"}
      }
    }
  },
  mounted() {
    this.setChart();
  },
  components: {
    // taskStatusOne,
    // taskStatusTwo
  }
};
</script>

<style lang="less" scoped>
.center {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  .left {
    padding: 4px;
    padding-bottom: 0px;
    width: 40%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 100%;
    }
  }
  .main {
    width: 58%;
    height: 98%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 20px;
    .item {
      border-radius: 5px;
      padding-top: 1rem;
      width: 32%;
      height: 70px;
      font-size: 14px;
      color: #4579de;
      margin-top: 20px;
    }
  }
  .right{
    width: 10%;
    .water{
      width: 40%;
    }
  }
}

</style>